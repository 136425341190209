import dayjs from "dayjs";
import { FC } from "react";
import { capitalize } from "lodash";
import { useTranslation } from "react-i18next";

// Helpers
import { totalMacro } from "@/_helpers/number_functions";

// Interfaces
import { IClientNutrition } from "@/interfaces/client/client_nutrition";

// Components
import { Box } from "@mui/material";
import { ColDef } from "@/components/datatable";
import ComplianceBar from "../components/compliance_bar";


const useNutritionDataTableHeaders = (): ColDef<IClientNutrition>[] => {

    const { t } = useTranslation();

    // const hasTarget = (target: number | null) => target !== null ? target : 0;

    const headers: ColDef<IClientNutrition>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '10%',
            render: (row) => dayjs.utc(row.date).format('DD/MM/YYYY')
        },
        {
            field: 'plan.name',
            headerName: t('components.dataTable.headers.day'),
            width: '20%',
            render: (row) => row.plan || row.legacy_plan ? capitalize(row.plan?.name ?? row.legacy_plan) : <span style={{color: 'var(--support-error)'}}>{t('general.noDailyCheckin')}</span>
        },
        {
            field: 'plan.calories',
            headerName: t('components.dataTable.headers.calories'),
            width: '10%',
            render: (row) => row.meals.length > 0 ? 
                <_ActualTarget 
                    actual={totalMacro(row.meals, 'calories')} 
                    target={row.plan?.calories ?? row.targets?.calories}
                    macro={false}
                    /> : 0,
        },
        {
            field: 'plan.protein',
            headerName: t('components.dataTable.headers.protein'),
            width: '10%',
            render: (row) => row.meals.length > 0 ? 
                <_ActualTarget 
                    actual={totalMacro(row.meals, 'protein')} 
                    target={row.plan?.protein ?? row.targets?.protein}
                    macro
                    /> : 0,
        },
        {
            field: 'plan.carbs',
            headerName: t('components.dataTable.headers.carbs'),
            width: '10%',
            render: (row) => row.meals.length > 0 ? 
                <_ActualTarget 
                    actual={totalMacro(row.meals, 'carbs')} 
                    target={row.plan?.carbs ?? row.targets?.carbs}
                    macro
                    /> : 0,
        },
        {
            field: 'plan.fat',
            headerName: t('components.dataTable.headers.fat'),
            width: '10%',
            render: (row) => row.meals.length > 0 ? 
                <_ActualTarget 
                    actual={totalMacro(row.meals, 'fat')} 
                    target={row.plan?.fat ?? row.targets?.fat}
                    macro
                    /> : 0,
        },
        {
            field: 'compliance',
            headerName: t('components.dataTable.headers.compliance'),
            width: '10%',
            render: (row) => <ComplianceBar actual={totalMacro(row.meals, 'calories')} target={row.plan?.calories ?? row.targets?.calories} />
                
        },
    ];

    return headers;
}

export default useNutritionDataTableHeaders;


interface _ActualTargetProps {
    actual: number;
    target?: number;
    macro: boolean;
}

const _ActualTarget: FC<_ActualTargetProps> = ({
    actual,
    target,
    macro
}) => {

    const { t } = useTranslation();

    return (
        <Box display="flex" minWidth="125px">
            <span>{`${actual}`}</span>
            {target !== null && target !== undefined && <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{`/ ${target}`}</span>}
            <span style={{marginLeft: '4px', color: 'var(--text-placeholder)'}}>{macro ? t('metrics.gram') : t('metrics.kcal')}</span>
        </Box>
    )
}