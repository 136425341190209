import dayjs from "dayjs";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

// Interfaces
import { IClientData } from "@/interfaces/client/client_data";

// Components
import Tag from "@/components/tag";
import { ColDef } from "@/components/datatable";
import { useNavigate, useParams } from "react-router-dom";
import { useGetClientQuery } from "@/repositories/client";


const useHealthTableHeaders = (): ColDef<IClientData>[] => {

    const { id } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: client } = useGetClientQuery(Number(id));
    const showRhr = client?.coach_settings.health.rhr;
    const showHrv = client?.coach_settings.health.hrv;
    const showBloodGlucose = client?.coach_settings.health.blood_glucose;
    const showBloodPressure = client?.coach_settings.health.blood_pressure;
    const showMenstrualCycle = client?.coach_settings.health.menstrual_cycle;

    useEffect(() => {
        if (Object.values(client?.coach_settings.health ?? {}).every(value => value === false)) navigate(`/clients/${id}/overview`);
    }, [id, client?.coach_settings.health, navigate]);

    const headers: ColDef<IClientData>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
            width: '20%',
            render: (row: IClientData) => dayjs.utc(row.date).format('DD/MM/YYYY')
        },
        ...showRhr ? [{
            field: 'rhr',
            headerName: t('components.dataTable.headers.rhr'),
            width: '15%',
            render: (row: IClientData) => row.rhr ? t('metrics.bpmValue', { value: row.rhr }) : '-'
        }] : [],
        ...showHrv ? [{
            field: 'hrv',
            headerName: t('components.dataTable.headers.hrv'),
            width: '15%',
            render: (row: IClientData) => row.hrv ? t('metrics.msValue', { value: row.hrv }) : '-'
        }] : [],
        ...showBloodGlucose ? [{
            field: 'blood_glucose',
            headerName: t('components.dataTable.headers.bloodGlucose'),
            width: '15%',
            render: (row: IClientData) => row.blood_glucose ? t('metrics.mmolValue', { value: row.blood_glucose }) : '-'
        }] : [],
        ...showBloodPressure ? [{
            field: 'blood_pressure',
            headerName: t('components.dataTable.headers.bloodPressure'),
            width: '15%',
            render: (row: IClientData) => row.blood_pressure ? t('metrics.mmhgValue', { value: `${row.blood_pressure.systolic} / ${row.blood_pressure.diastolic}`}) : '-'
        }] : [],
        ...showMenstrualCycle ? [{
            field: 'menstrual_cycle',
            headerName: t('components.dataTable.headers.menstrualCycle'),
            width: '15%',
            render: (row: IClientData) => row.menstrual_cycle ? <MenstrualCycle value={row.menstrual_cycle} /> : '-'
        }] : []
    ];

    return headers;
}

export default useHealthTableHeaders;

interface _MenstrualCycleProps {
    value: string;
}

const MenstrualCycle: FC<_MenstrualCycleProps> = ({ value }) => {

    const { t } = useTranslation();

    const setTag = () => {
        switch (value) {
            case 'Menstruation':
                return <Tag label={t('metrics.menstrualCycle.menstruation')} colour="red" />
            case 'Follicular':
                return <Tag label={t('metrics.menstrualCycle.follicular')} colour="blue" />
            case 'Ovulation':
                return <Tag label={t('metrics.menstrualCycle.ovulation')} colour="green" />
            case 'Luteal':
                return <Tag label={t('metrics.menstrualCycle.luteal')} colour="grey" />
            default:
                return '-';
        }
    }

    return (
        setTag()
    )
}